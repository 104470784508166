module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es","ca","en"],"redirect":false,"generateDefaultLanguagePage":false,"defaultLanguage":"es","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"saveMissing":false},"pages":[{"matchPath":"/","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 426px)","sm":"(max-width: 576px)","md":"(max-width: 767px)"}},
    }]
