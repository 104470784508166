exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ca-agencia-tsx": () => import("./../../../src/pages/ca/agencia.tsx" /* webpackChunkName: "component---src-pages-ca-agencia-tsx" */),
  "component---src-pages-ca-avis-de-confidencialitat-tsx": () => import("./../../../src/pages/ca/avis-de-confidencialitat.tsx" /* webpackChunkName: "component---src-pages-ca-avis-de-confidencialitat-tsx" */),
  "component---src-pages-ca-contacte-tsx": () => import("./../../../src/pages/ca/contacte.tsx" /* webpackChunkName: "component---src-pages-ca-contacte-tsx" */),
  "component---src-pages-ca-equip-tsx": () => import("./../../../src/pages/ca/equip.tsx" /* webpackChunkName: "component---src-pages-ca-equip-tsx" */),
  "component---src-pages-ca-gracies-tsx": () => import("./../../../src/pages/ca/gracies.tsx" /* webpackChunkName: "component---src-pages-ca-gracies-tsx" */),
  "component---src-pages-ca-index-tsx": () => import("./../../../src/pages/ca/index.tsx" /* webpackChunkName: "component---src-pages-ca-index-tsx" */),
  "component---src-pages-ca-legal-tsx": () => import("./../../../src/pages/ca/legal.tsx" /* webpackChunkName: "component---src-pages-ca-legal-tsx" */),
  "component---src-pages-ca-network-tsx": () => import("./../../../src/pages/ca/network.tsx" /* webpackChunkName: "component---src-pages-ca-network-tsx" */),
  "component---src-pages-ca-proteccio-de-dades-tsx": () => import("./../../../src/pages/ca/proteccio-de-dades.tsx" /* webpackChunkName: "component---src-pages-ca-proteccio-de-dades-tsx" */),
  "component---src-pages-en-agency-tsx": () => import("./../../../src/pages/en/agency.tsx" /* webpackChunkName: "component---src-pages-en-agency-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-data-protection-tsx": () => import("./../../../src/pages/en/data-protection.tsx" /* webpackChunkName: "component---src-pages-en-data-protection-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-legal-tsx": () => import("./../../../src/pages/en/legal.tsx" /* webpackChunkName: "component---src-pages-en-legal-tsx" */),
  "component---src-pages-en-network-tsx": () => import("./../../../src/pages/en/network.tsx" /* webpackChunkName: "component---src-pages-en-network-tsx" */),
  "component---src-pages-en-notice-of-confidentiality-tsx": () => import("./../../../src/pages/en/notice-of-confidentiality.tsx" /* webpackChunkName: "component---src-pages-en-notice-of-confidentiality-tsx" */),
  "component---src-pages-en-team-tsx": () => import("./../../../src/pages/en/team.tsx" /* webpackChunkName: "component---src-pages-en-team-tsx" */),
  "component---src-pages-en-thank-you-tsx": () => import("./../../../src/pages/en/thank-you.tsx" /* webpackChunkName: "component---src-pages-en-thank-you-tsx" */),
  "component---src-pages-es-agencia-tsx": () => import("./../../../src/pages/es/agencia.tsx" /* webpackChunkName: "component---src-pages-es-agencia-tsx" */),
  "component---src-pages-es-aviso-de-confidencialidad-tsx": () => import("./../../../src/pages/es/aviso-de-confidencialidad.tsx" /* webpackChunkName: "component---src-pages-es-aviso-de-confidencialidad-tsx" */),
  "component---src-pages-es-contacto-tsx": () => import("./../../../src/pages/es/contacto.tsx" /* webpackChunkName: "component---src-pages-es-contacto-tsx" */),
  "component---src-pages-es-equipo-tsx": () => import("./../../../src/pages/es/equipo.tsx" /* webpackChunkName: "component---src-pages-es-equipo-tsx" */),
  "component---src-pages-es-gracias-tsx": () => import("./../../../src/pages/es/gracias.tsx" /* webpackChunkName: "component---src-pages-es-gracias-tsx" */),
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-es-legal-tsx": () => import("./../../../src/pages/es/legal.tsx" /* webpackChunkName: "component---src-pages-es-legal-tsx" */),
  "component---src-pages-es-network-tsx": () => import("./../../../src/pages/es/network.tsx" /* webpackChunkName: "component---src-pages-es-network-tsx" */),
  "component---src-pages-es-proteccion-de-datos-tsx": () => import("./../../../src/pages/es/proteccion-de-datos.tsx" /* webpackChunkName: "component---src-pages-es-proteccion-de-datos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-press-detail-template-press-detail-template-tsx": () => import("./../../../src/templates/PressDetailTemplate/PressDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-press-detail-template-press-detail-template-tsx" */),
  "component---src-templates-press-list-template-press-list-template-tsx": () => import("./../../../src/templates/PressListTemplate/PressListTemplate.tsx" /* webpackChunkName: "component---src-templates-press-list-template-press-list-template-tsx" */),
  "component---src-templates-producer-detail-template-producer-detail-template-tsx": () => import("./../../../src/templates/ProducerDetailTemplate/ProducerDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-producer-detail-template-producer-detail-template-tsx" */),
  "component---src-templates-producer-template-producer-template-tsx": () => import("./../../../src/templates/ProducerTemplate/ProducerTemplate.tsx" /* webpackChunkName: "component---src-templates-producer-template-producer-template-tsx" */),
  "component---src-templates-project-detail-template-project-detail-template-tsx": () => import("./../../../src/templates/ProjectDetailTemplate/ProjectDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-project-detail-template-project-detail-template-tsx" */),
  "component---src-templates-project-list-template-project-list-template-tsx": () => import("./../../../src/templates/ProjectListTemplate/ProjectListTemplate.tsx" /* webpackChunkName: "component---src-templates-project-list-template-project-list-template-tsx" */),
  "component---src-templates-talent-detail-template-talent-detail-template-tsx": () => import("./../../../src/templates/TalentDetailTemplate/TalentDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-talent-detail-template-talent-detail-template-tsx" */),
  "component---src-templates-talent-list-template-talent-list-template-tsx": () => import("./../../../src/templates/TalentListTemplate/TalentListTemplate.tsx" /* webpackChunkName: "component---src-templates-talent-list-template-talent-list-template-tsx" */)
}

